<i18n lang="json">
    {
      "ru": {
        "title": "Куда поехать",
        "options": "{n} вариантов | {n} вариант | {n} варианта | {n} вариантов",
        "cities": {
          "spb": "Санкт-Петербург",
          "moscow": "Москва",
          "kazan": "Казань",
          "kaliningrad": "Калининград",
          "sochi": "Сочи",
          "sheregesh": "Шерегеш",
          "gelendzhik": "Геленджик",
          "krasnayaPolyana": "Красная Поляна",
          "sirius": "Сириус",
          "arhyz": "Архыз",
          "anapa": "Анапа",
          "minsk": "Минск",
          "vladivostok": "Владивосток",
          "nn": "Нижний Новгород",
          "mahachkala": "Дагестан",
          "ekaterinburg": "Екатеринбург",
          "kislovodsk": "Кисловодск",
          "krasnodar": "Краснодар"
        }
      },
      "en": {
        "title": "Where to go",
        "options": "{n} options | {n} option | {n} options | {n} options",
        "cities": {
          "spb": "Saint Petersburg",
          "moscow": "Moscow",
          "kazan": "Kazan",
          "kaliningrad": "Kaliningrad",
          "sochi": "Sochi",
          "sheregesh": "Sheregesh",
          "gelendzhik": "Gelendzhik",
          "krasnayaPolyana": "Krasnaya Polyana",
          "sirius": "Sirius",
          "arhyz": "Arhyz",
          "anapa": "Anapa",
          "minsk": "Minsk",
          "vladivostok": "Vladivostok",
          "nn": "Nizhny Novgorod",
          "mahachkala": "Dagestan",
          "ekaterinburg": "Ekaterinburg",
          "kislovodsk": "Kislovodsk",
          "krasnodar": "Krasnodar"
        }
      }
    }
</i18n>

<template>
  <div class="where-go">
    <h2 class="where-go__title heading-h6">{{ t("title") }}</h2>

    <div class="where-go__group">
      <a
        :href="card.link"

        v-for="(card, i) in citiesCards"
        :key="i"
      >
        <div class="where-go__card">
        <span class="where-go__count card-label semibold-p7">
          {{ numberFormat(t("options", card.count)) }}
        </span>
        <picture>
          <source
            v-if="card.imgWebp"
            :srcset="card.imgWebp"
            type="image/webp"
          />
          <img
            :src="card.img"
            loading="lazy"
            :alt="t(`cities.${card.title}`)"
          />
        </picture>
        </div>
        <span
            class="where-go__card-title medium-p5"
        >
          {{ t(`cities.${card.title}`) }}
        </span>
      </a>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import numberFormat from "@/utils/numberFormat";

const { t } = useI18n();

const images = import.meta.glob([
  "assets/img/main/main_spb-s.jpg",
  "assets/img/main/main_spb-s.webp",
  "assets/img/main/main_moscow-s.jpg",
  "assets/img/main/main_moscow-s.webp",
  "assets/img/main/main_kazan-s.jpg",
  "assets/img/main/main_kazan-s.webp",
  "assets/img/main/popular-kaliningrad-s.jpg",
  "assets/img/main/popular-kaliningrad-s.webp",
  "assets/img/main/sochi-s.jpg",
  "assets/img/main/sochi-s.webp",
  "assets/img/main/popular-gelendzhik.jpg",
  "assets/img/main/popular-gelendzhik.webp",
  "assets/img/main/popular-sirius.jpg",
  "assets/img/main/popular-sirius.webp",
  "assets/img/main/anapa.jpg",
  "assets/img/main/anapa.webp",
  "assets/img/main/popular-minsk-s.jpg",
  "assets/img/main/popular-minsk-s.webp",
  "assets/img/main/popular-vladivostok-s.jpg",
  "assets/img/main/popular-vladivostok-s.webp",
  "assets/img/main/popular-mahachkala.jpg",
  "assets/img/main/popular-mahachkala.webp",
  "assets/img/main/ekaterinburg-s.jpg",
  "assets/img/main/ekaterinburg-s.webp",
  "/assets/img/main/kislovodsk-s.jpg",
  "/assets/img/main/kislovodsk-s.webp",
  "/assets/img/main/krasnodar-s.jpg",
  "/assets/img/main/krasnodar-s.webp",
], { eager: true, query: "?url", import: "default" });
//TODO: Вынести названия в i18n и количество перобразовать в числа
const citiesCards = [
  {
    title: "spb",
    count: 21574,
    link: "//spb.sutochno.ru?from=mainpage",
    img: images["/assets/img/main/main_spb-s.jpg"],
    imgWebp: images["/assets/img/main/main_spb-s.webp"],
  },
  {
    title: "moscow",
    count: 14361,
    link: "//www.sutochno.ru?from=mainpage",
    img: images["/assets/img/main/main_moscow-s.jpg"],
    imgWebp: images["/assets/img/main/main_moscow-s.webp"],
  },
  {
    title: "kazan",
    count: 6212,
    link: "//kazan.sutochno.ru?from=mainpage",
    img: images["/assets/img/main/main_kazan-s.jpg"],
    imgWebp: images["/assets/img/main/main_kazan-s.webp"],
  },
  {
    title: "kaliningrad",
    count: 5331,
    link: "//kaliningrad.sutochno.ru?from=mainpage",
    img: images["/assets/img/main/popular-kaliningrad-s.jpg"],
    imgWebp: images["/assets/img/main/popular-kaliningrad-s.webp"],
  },
  {
    title: "sochi",
    count: 22269,
    link: "//sochi.sutochno.ru?from=mainpage",
    img: images["/assets/img/main/sochi-s.jpg"],
    imgWebp: images["/assets/img/main/sochi-s.webp"],
  },
  /* {
    title: "sheregesh",
    count: 854,
    link: "//sheregesh.sutochno.ru?from=mainpage",
    img: images["/assets/img/main/popular-sheregesh-s.jpg"],
    imgWebp: images["/assets/img/main/popular-sheregesh-s.webp"],
  }, */
  {
    title: "gelendzhik",
    count: 5013,
    link: "//gelendzhik.sutochno.ru?from=mainpage",
    img: images["/assets/img/main/popular-gelendzhik.jpg"],
    imgWebp: images["/assets/img/main/popular-gelendzhik.webp"],
  },
  /* {
    title: "krasnayaPolyana",
    count: 1737,
    link: "//krasnaya-polyana.sutochno.ru/?from=mainpage",
    img: images["/assets/img/main/popular-krasnaya-polyana-s.jpg"],
    imgWebp: images["/assets/img/main/popular-krasnaya-polyana-s.webp"],
  }, */
  {
    title: "sirius",
    count: 3660,
    link: "//adler.sutochno.ru/sirius?from=mainpage",
    img: images["/assets/img/main/popular-sirius.jpg"],
    imgWebp: images["/assets/img/main/popular-sirius.webp"],
  },
  /* {
    title: "arhyz",
    count: 997,
    link: "//cherkessk.sutochno.ru/arhyz?from=mainpage",
    img: images["/assets/img/main/arhyz-s.jpg"],
    imgWebp: images["/assets/img/main/arhyz-s.webp"],
  }, */
  {
    title: "anapa",
    count: 7370,
    link: "//anapa.sutochno.ru/?from=mainpage",
    img: images["/assets/img/main/anapa.jpg"],
    imgWebp: images["/assets/img/main/anapa.webp"],
  },
  {
    title: "minsk",
    count: 2329,
    link: "//minsk.sutochno.ru?from=mainpage",
    img: images["/assets/img/main/popular-minsk-s.jpg"],
    imgWebp: images["/assets/img/main/popular-minsk-s.webp"],
  },
  {
    title: "vladivostok",
    count: 2175,
    link: "//vl.sutochno.ru?from=mainpage",
    img: images["/assets/img/main/popular-vladivostok-s.jpg"],
    imgWebp: images["/assets/img/main/popular-vladivostok-s.webp"],
  },
  /* {
    title: "nn",
    count: 2603,
    link: "//nn.sutochno.ru/?from=mainpage",
    img: images["/assets/img/main/popular-nn-s.jpg"],
    imgWebp: images["/assets/img/main/popular-nn-s.webp"],
  }, */
  {
    title: "mahachkala",
    count: 7683,
    link: "//mahachkala.sutochno.ru/region?from=mainpage",
    img: images["/assets/img/main/popular-mahachkala.jpg"],
    imgWebp: images["/assets/img/main/popular-mahachkala.webp"],
  },
  {
    title: "ekaterinburg",
    count: 3321,
    link: "//ekaterinburg.sutochno.ru?from=mainpage",
    img: images["/assets/img/main/ekaterinburg-s.jpg"],
    imgWebp: images["/assets/img/main/ekaterinburg-s.webp"],
  },
  {
    title: "kislovodsk",
    count: 3024,
    link: "//kislovodsk.sutochno.ru?from=mainpage",
    img: images["/assets/img/main/kislovodsk-s.jpg"],
    imgWebp: images["/assets/img/main/kislovodsk-s.webp"],
  },
  {
    title: "krasnodar",
    count: 4682,
    link: "//krasnodar.sutochno.ru/?from=mainpage",
    img: images["/assets/img/main/krasnodar-s.jpg"],
    imgWebp: images["/assets/img/main/krasnodar-s.webp"],
  },
];
</script>

<style lang="scss" scoped>
.where-go {
  margin-top: 50px;

  &__title {
    text-align: center;
  }

  &__group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-top: 30px;
  }

  &__card {
    display: inline-block;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: calc(100% - 20px);

    &:hover {
      img {
        transform: scale(1.1);
      }
    }

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      background: linear-gradient(
        180deg,
        rgba(48, 48, 48, 0) 53.12%,
        rgba(31, 31, 31, 0.46) 100%
      );
      z-index: 1;
    }

    img {
      width: 100%;
      height: 100%;
      transition: 0.3s;
      object-fit: cover;
    }

    &--small {
      width: calc(16.6666% - 13.4px);

      .where-go__count {
        top: 16px;
        left: 16px;
      }

      .where-go__card-title {
        left: 16px;
        bottom: 14px;
      }
    }
  }

  &__card-title {
    color: var(--color-main-black);
    display: block;
    width: 100%;
    text-align: center;
    // margin-top: 9px;
  }

  &__count {
    left: 8px;
    top: 8px;
  }
}
</style>
