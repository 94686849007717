// находит число в строке, ставит пробелы через каждые 3 цифры с конца

export default function numberFormat(val){
  if (typeof val === "string") {
    let arr = val.split(" ");
    let formated = arr.map((item) => {
      if (!isNaN(item)) {
        return new Intl.NumberFormat("ru-RU").format(item);
      }
      return item
    })

    return formated.join(" ");
  }
  return val;
}