
export default () => {
  useHead({
    script: [
      {
        type: "application/ld+json",
        children: {
          "@context": "http://schema.org",
          "@type": "WebSite",
          "url": "https://sutochno.ru/",
          "potentialAction": {
            "@type": "SearchAction",
            "target": "https://sutochno.ru/front/searchapp/search?term={search_term_string}",
            "query-input": "required name=search_term_string"
          }
        }
      }
    ]
  });
}