<i18n lang="json">
{
  "ru": {
    "title": "Популярно за рубежом",
    "cityCount": "{n} городов| {n} город | {n} города |{n} городов",
    "showAll": "Показать всё",
    "hide": "Скрыть"
  },
  "en": {
    "title": "Popular abroad"
  }
}
</i18n>

<template>
  <div class="abroad">
    <h2 class="abroad__title heading-h6">{{ t("title") }}</h2>
    <div class="abroad__group">
      <TransitionGroup name="list">
        <a
          :href="country.link"
          class="abroad__el"
          v-for="(country, i) in countries"
          :key="i"
        >
          <img
            :src="country.icon"
            :alt="country.title"
          />
          <div class="abroad__text">
            <span class="title semibold-p8">{{ country.title }}</span>
            <span class="subtitle medium-p7">{{ t('cityCount', country.cityCounts) }}</span>
          </div>
        </a>
      </TransitionGroup>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useI18n } from 'vue-i18n';

const flags = import.meta.glob("assets/img/main/flags/*.svg", { eager: true, query: "?url", import: "default" });

const { t } = useI18n();

//TODO: Переделать на i18n
const countries = [
  {
    title: "Беларусь",
    subtitle: "Минск, Брест, Витебск",
    icon: flags["/assets/img/main/flags/belarus.svg"],
    link: "/belarus",
    cityCounts: 40,
  },
  {
    title: "Казахстан",
    subtitle: "Астана, Алматы, Актау",
    icon: flags["/assets/img/main/flags/kazakhstan.svg"],
    link: "/kazakhstan",
    cityCounts: 21,
  },
  {
    title: "Абхазия",
    subtitle: "Гагра, Сухум, Пицунда",
    icon: flags["/assets/img/main/flags/abhasia.svg"],
    link: "//abkhazia.sutochno.ru",
    cityCounts: 7,
  },
  {
    title: "Грузия",
    subtitle: "Тбилиси, Батуми",
    icon: flags["/assets/img/main/flags/grusia.svg"],
    link: "/georgia",
    cityCounts: 12,
  },
  {
    title: "Армения",
    subtitle: "Ереван, Дилижан",
    icon: flags["/assets/img/main/flags/armenia.svg"],
    link: "/armenia",
    cityCounts: 9,
  },
  {
    title: "Турция",
    subtitle: "Анталия, Стамбул, Аланья",
    icon: flags["/assets/img/main/flags/turkey.svg"],
    link: "/turkey",
    cityCounts: 17,
  },
  {
    title: "Египет",
    subtitle: "Шарм-эль-Шейх, Хургада",
    icon: flags["/assets/img/main/flags/egypt.svg"],
    link: "/egypt",
    cityCounts: 9,
  },
  {
    title: "Таиланд",
    subtitle: "Пхукет, Паттайя",
    icon: flags["/assets/img/main/flags/thailand.svg"],
    link: "/thailand",
    cityCounts: 2,
  },
  {
    title: "Азербайджан",
    subtitle: "Баку, Сиазань",
    icon: flags["/assets/img/main/flags/azerbaidghan.svg"],
    link: "/azerbaijan",
    cityCounts: 2,
  },
  {
    title: "Узбекистан",
    subtitle: "Ташкент, Самарканд",
    icon: flags["/assets/img/main/flags/usbekistan.svg"],
    link: "/uzbekistan",
    cityCounts: 5,
  },
  {
    title: "Киргизия",
    subtitle: "Бишкек, Каракол",
    icon: flags["/assets/img/main/flags/kirgisia.svg"],
    link: "/kyrgyzstan",
    cityCounts: 2,
  },
  {
    title: "ОАЭ",
    subtitle: "Дубай, Умм-эль-Кайвайн",
    icon: flags["/assets/img/main/flags/oae.svg"],
    link: "/united-arab-emirates",
    cityCounts: 2,
  },
];


</script>

<style lang="scss" scoped>
.abroad {
  margin-top: 50px;
  margin-bottom: 30px;

  &__title {
    text-align: center;
  }

  &__group {
    display: flex;
    flex-wrap: wrap;
    row-gap: 38px;
    margin-top: 23px;
    margin-bottom: 26px;
    column-gap: 16px;
  }

  &__el {
    max-width: 152px;
    width: 50%;
    display: flex;
    align-items: center;
    gap: 12px;
    color: var(--color-black);
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 6px;

    .subtitle {
      color: var(--color-gray-g5);
    }
  }
  &__toggle-list-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-main-black);
    background-color: var(--color-gray-g3);
    width: 100%;
    padding: 11px 0;
    border: none;
    border-radius: 10px;
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
