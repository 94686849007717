<i18n lang="json">
    {
      "ru": {
        "title": "Популярно за рубежом",
      },
      "en": {
        "title": "Popular abroad",
      }
    }
</i18n>

<template>
  <div class="abroad">
    <h2 class="abroad__title heading-h3">{{ t("title") }}</h2>
    <div class="abroad__group">
      <a
        :href="country.link"
        class="abroad__el"
        v-for="(country, i) in countries"
        :key="i"
      >
        <img
          :src="country.icon"
          :alt="country.title"
        />
        <div class="abroad__text">
          <span class="title semibold-p4">{{ country.title }}</span>
          <span class="subtitle medium-p6">{{ country.subtitle }}</span>
        </div>
      </a>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const flags = import.meta.glob("assets/img/main/flags/*.svg", { eager: true, query: "?url", import: "default" });

const { t } = useI18n();

//TODO: Переделать на i18n
const countries = [
  {
    title: "Беларусь",
    subtitle: "Минск, Брест, Витебск",
    icon: flags["/assets/img/main/flags/belarus.svg"],
    link: "/belarus",
  },
  {
    title: "Казахстан",
    subtitle: "Астана, Алматы, Актау",
    icon: flags["/assets/img/main/flags/kazakhstan.svg"],
    link: "/kazakhstan",
  },
  {
    title: "Абхазия",
    subtitle: "Гагра, Сухум, Пицунда",
    icon: flags["/assets/img/main/flags/abhasia.svg"],
    link: "//abkhazia.sutochno.ru",
  },
  {
    title: "Грузия",
    subtitle: "Тбилиси, Батуми",
    icon: flags["/assets/img/main/flags/grusia.svg"],
    link: "/georgia",
  },
  {
    title: "Армения",
    subtitle: "Ереван, Дилижан",
    icon: flags["/assets/img/main/flags/armenia.svg"],
    link: "/armenia",
  },
  {
    title: "Турция",
    subtitle: "Анталия, Стамбул, Аланья",
    icon: flags["/assets/img/main/flags/turkey.svg"],
    link: "/turkey",
  },
  {
    title: "Азербайджан",
    subtitle: "Баку, Сиазань",
    icon: flags["/assets/img/main/flags/azerbaidghan.svg"],
    link: "/azerbaijan",
  },
  {
    title: "Киргизия",
    subtitle: "Бишкек, Каракол",
    icon: flags["/assets/img/main/flags/kirgisia.svg"],
    link: "/kyrgyzstan",
  },
  {
    title: "Египет",
    subtitle: "Шарм-эль-Шейх, Хургада",
    icon: flags["/assets/img/main/flags/egypt.svg"],
    link: "/egypt",
  },
  {
    title: "Таиланд",
    subtitle: "Пхукет, Паттайя",
    icon: flags["/assets/img/main/flags/thailand.svg"],
    link: "/thailand",
  },
  {
    title: "Узбекистан",
    subtitle: "Ташкент, Самарканд",
    icon: flags["/assets/img/main/flags/usbekistan.svg"],
    link: "/uzbekistan",
  },
  {
    title: "ОАЭ",
    subtitle: "Дубай, Умм-эль-Кайвайн",
    icon: flags["/assets/img/main/flags/oae.svg"],
    link: "/united-arab-emirates",
  },
];
</script>

<style lang="scss" scoped>
.abroad {
  margin-top: 71px;

  &__title {
    text-align: center;
  }

  &__group {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 38px;
    margin-top: 64px;
  }

  &__el {
    display: flex;
    align-items: center;
    gap: 14px;
    color: var(--color-black);
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 6px;

    .subtitle {
      color: var(--color-gray-g5);
    }
  }
}
</style>
