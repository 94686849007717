<i18n lang="json">
    {
      "ru": {
        "title": "Бронировать в приложении Суточно.ру ещё удобнее и быстрее!",
        "subtitle": "Все варианты, брони и переписка с хозяевами у вас в кармане.",
        "camera": "Наведите камеру на QR-код, чтобы скачать приложение",
      },
      "en": {
        "title": "Booking in the Sutochno.ru application is even more convenient and faster!",
        "subtitle": "All options, reservations and correspondence with the owners are in your pocket.",
        "camera": "Point your camera at the QR code to download the app",
      }
    }
</i18n>

<template>
  <div class="bookin-app">
    <div class="bookin-app__textes">
      <p class="bookin-app__title heading-h5">{{ t("title") }}</p>
      <p class="bookin-app__subtitle regular-p5">{{ t("subtitle") }}</p>
    </div>
    <div class="bookin-app__images">
      <div class="bookin-app__qr">
        <p class="bookin-app__qr-text medium-p7">{{ t("camera") }}</p>
        <div class="bookin-app__code-img">
          <img src="@/assets/img/main/qr-code.png" alt="QR-код Суточно.ру" />
        </div>
      </div>
      <div class="bookin-app__apps">
        <a
          href="https://redirect.appmetrica.yandex.com/serve/820833924912576402"
          target="_blank"
        >
          <img
            src="@/assets/img/main/icon-google-play.png"
            alt="Суточно.ру Google Play"
          />
        </a>
        <a
          href="https://redirect.appmetrica.yandex.com/serve/892891521215365358"
          target="_blank"
        >
          <img
            src="@/assets/img/main/icon-appstore.png"
            alt="Суточно.ру AppStore"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<style lang="scss" scoped>
.bookin-app {
  margin-top: 82px;
  background-color: var(--color-gray-g3);
  border-radius: 30px;
  padding: 34px 36px;
  padding-left: 179px;
  background-image: url("@/assets/img/main/logo-app.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  display: flex;
  gap: 37px;

  @media (max-width: 1160px) {
    padding: 15px;
    padding-left: 143px;
    gap: 3px;
  }

  @media (max-width: 920px) {
    padding-left: 200px;
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;
  }


  &__title {
    width: 435px;

    @media (max-width: 1160px) {
      width: 80%;
    }
  }

  &__subtitle {
    color: var(--color-gray-g5);
    white-space: nowrap;

    @media (max-width: 1160px) {
      margin-bottom: 0;
      white-space: normal;
    }
  }

  &__apps {
    display: flex;
    flex-direction: column;
    min-width: 152px;
    max-width: 152px;
    justify-content: flex-end;
    margin-right: -10px;

    @media (max-width: 1160px) {
      max-width: 120px;
      min-width: 120px;
      margin-right: 0;
    }

    a {
      margin-bottom: -15px;

      @media (max-width: 1160px) {
        margin-bottom: -10px;
      }
    }

    img {
      width: 100%;
    }
  }

  &__images {
    display: flex;
    gap: 26px;

    @media (max-width: 1160px) {
      gap: 0;
    }
  }

  &__qr {
    display: flex;
    align-items: flex-end;
  }

  &__code-img {
    background: var(--color-white);
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.02),
      0px 5px 10px rgba(20, 45, 136, 0.02);
    min-width: 91px;
    max-width: 91px;
    height: 90px;

    img {
      width: 100%;
    }
  }

  &__qr-text {
    min-width: 160px;
    color: var(--color-gray-g5);
    background: var(--color-gray-g2);
    border-radius: 18px 0px 18px 20px;
    padding: 10px 15px;
    margin-bottom: 0;
    margin-right: 6px;

    @media (max-width: 1160px) {
      margin-bottom: 0;
    }
  }
}
</style>
