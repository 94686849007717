import gtmDataPage from "./gtmDataPage";

/**
 * Работа с главной страницей
 *
 * @module Main
 */
export default {
  gtmDataPage,
};
