<i18n lang="json">
    {
        "ru": {

        "ideaLabel": "Маршруты",
        "ideaLabel2": "С детьми",
        "ideaLabel3": "Маршруты",
        "ideaLabel4": "Что посмотреть",
        "ideaLabel5": "Telegram",

        "ideaDescription": "Недорогие курорты для&nbsp;отдыха на&nbsp;море",
        "ideaDescription2": "Пляжи Краснодарского края",
        "ideaDescription3": "На машине по России",
        "ideaDescription4": "Гид по Кавказским Минеральным Водам",
        "ideaDescription5": "Больше интересного в Телеграм-канале",
        "title": "Идеи для отпуска",
        "blog": "Перейти в блог"
        },
        "en": {

        "ideaLabel": "Routes",
        "ideaLabel2": "With children",
        "ideaLabel3": "Routes",
        "ideaLabel4": "What to see",
        "ideaLabel5": "Telegram",

        "ideaDescription": "Inexpensive resorts for seaside holidays",
        "ideaDescription2": "Beaches of the Krasnodar region",
        "ideaDescription3": "By car in Russia",
        "ideaDescription4": "Guide to Caucasian Mineral Waters",
        "ideaDescription5": "More interesting in the Telegram channel",
        "title": "Travel Ideas",
        "blog": "Go to blog"
        }
    }
</i18n>

<template>
  <div class="route-direction">
    <h2 class="route-direction__title">
      {{ t("title") }}
    </h2>
    <swiper :slides-per-view="'auto'" :space-between="14" class="route-direction__slider">
      <swiper-slide v-for="(card, i) in directionCards" :key="i">
        <div
          class="route-direction__card"
          :class="{ 'route-direction__card--empty': !card?.img }"
        >
          <a :href="card.link" :target="card?.img ? '_self' : '_blank'">
            <span
              class="route-direction__card-label card-label"
              :class="{
                'card-label--blue': !card?.img,
                [!card?.img ? 'medium-p6' : 'semibold-p6']: true,
              }"
            >
              {{ t(`${card.label}`) }}
            </span>

            <picture v-if="card?.img">
              <source
                v-if="card.imgWebp"
                :srcset="card.imgWebp"
                type="image/webp"
              />
              <img
                :src="card.img"
                loading="lazy"
                :alt="t(`${card.description}`)"
              />
            </picture>
          </a>
        </div>
        <span class="route-direction__card-title heading-h6" v-html="t(card.description)"></span>
      </swiper-slide>
    </swiper>

    <a href="https://sutochno.ru/info?from=mainpage" class="route-direction__blog">
      {{ t("blog") }}
    </a>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useI18n } from 'vue-i18n';

// Import Swiper styles
import 'swiper/css';


const images = import.meta.glob([
  "assets/img/main/naMore.jpg",
  "assets/img/main/naMore.webp",
  "assets/img/main/Beaches-Krasnodar.jpg",
  "assets/img/main/Beaches-Krasnodar.webp",
  "assets/img/main/By-car-in-Russia.jpg",
  "assets/img/main/By-car-in-Russia.webp",
  "assets/img/main/otdyhNaKmv.jpg",
  "assets/img/main/otdyhNaKmv.webp",
], { eager: true, query: "?url", import: "default" });

const directionCards = [
  {
    link: "//sutochno.ru/info/gde-nedorogo-otdohnut-na-more-letom?from=mainpage",
    label: "ideaLabel3",
    description: "ideaDescription",
    img: images["/assets/img/main/naMore.jpg"],
    imgWebp: images["/assets/img/main/naMore.webp"],
  },
  {
    link: "/info/plyazhi-krasnodarskogo-kraya?from=mainpage",
    label: "ideaLabel2",
    description: "ideaDescription2",
    img: images["/assets/img/main/Beaches-Krasnodar.jpg"],
    imgWebp: images["/assets/img/main/Beaches-Krasnodar.webp"],
  },
  {
    link: "/info/na-mashine-po-rossii?from=mainpage",
    label: "ideaLabel",
    description: "ideaDescription3",
    img: images["/assets/img/main/By-car-in-Russia.jpg"],
    imgWebp: images["/assets/img/main/By-car-in-Russia.webp"],
  },
  {
    link: "//sutochno.ru/info/otdyh-na-kmv?from=mainpage",
    label: "ideaLabel4",
    description: "ideaDescription4",
    img: images["/assets/img/main/otdyhNaKmv.jpg"],
    imgWebp: images["/assets/img/main/otdyhNaKmv.webp"],
  },
  {
    link: "https://t.me/sutochnoru",
    label: "ideaLabel5",
    description: "ideaDescription5",
  },
];

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  computed: {
    directionCards() {
      return directionCards;
    },
    getCntSlide(){
      return window.innerWidth / 146;
    }
  },

};
</script>

<style lang="scss" scoped>
.route-direction {
  width: 100%;
  :deep() {
    .swiper-slide {
      width: 130px;

      &:first-child{
        margin-left: 20px;
      }
      &:last-child{
        margin-right: 20px;
      }
    }
  }

  &__title {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: var(--color-main-black);
  }

  &__card {
    position: relative;
    width: 130px;
    height: 154px;
    border-radius: 16px;
    overflow: hidden;


    .card-label {
      left: 8px;
      padding: 3px 7px;
      position: absolute;
      top: 8px;
      z-index: 2;
      font-size: 11px;
      line-height: 12px;
    }

    a {
      position: relative;
      display: block;
      height: 100%;
      border-radius: 16px;
      overflow: hidden;

      &:hover {
        img {
          transform: scale(1.1);
        }
      }

      &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 48.28%,
          rgba(0, 0, 0, 0.3) 100%
        );
        z-index: 1;
      }
    }

    &--empty {
      padding-right: 16px;
      box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.02),
        0px 5px 10px rgba(20, 45, 136, 0.02);

      a {
        background: var(--color-white);
        border-radius: 18px;
        z-index: 2;

        &:before {
          content: none;
        }
      }

      &:before,
      &:after {
        content: "";
        width: 91px;
        height: 100%;
        border-radius: 18px;
        position: absolute;
        top: 0;
      }

      &:before {
        background: #edf0f9;
        right: 8px;
        box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.02),
          0px 5px 10px rgba(20, 45, 136, 0.02);
        z-index: 1;
      }

      &:after {
        background: #e2e6f0;
        right: 0;
        box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.08),
          0px 4px 15px rgba(20, 45, 136, 0.06);
        z-index: 0;
      }

      .route-direction__card-title {
        color: var(--color-main-black);
        text-shadow: none;
        font-size: 17px;
        padding-right: 25px;
      }
    }
  }

  &__card-title {
    display: inline-block;
    margin-top: 10px;
    width: 130px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.2px;
    color: var(--color-main-black);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
  }

  &__blog{
    margin: 16px 20px 0;
    // width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-gray-g3);
    border-radius: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-main-black)
  }
}
</style>
