<i18n lang="json">
    {
      "ru": {
        "title": "Бронировать в приложении Суточно.ру — ещё удобнее и&nbsp;быстрее!",
        "subtitle": "Все варианты, брони и переписка с хозяевами у вас в кармане.",
        "camera": "Наведите камеру на QR-код, чтобы скачать приложение",
      },
      "en": {
        "title": "Booking in the Sutochno.ru application is even more convenient and faster!",
        "subtitle": "All options, reservations and correspondence with the owners are in your pocket.",
        "camera": "Point your camera at the QR code to download the app",
      }
    }
</i18n>

<template>
  <div class="bookin-app">
    <div class="bookin-app__textes">
      <p class="bookin-app__title heading-h6" v-html="t('title')"></p>
      <p class="bookin-app__subtitle regular-p5">{{ t("subtitle") }}</p>
      <a
        :href="
          isIOS
            ? 'https://redirect.appmetrica.yandex.com/serve/892891521215365358'
            : 'https://redirect.appmetrica.yandex.com/serve/820833924912576402'
        "
        target="_blank"
        class="bookin-app__button"
      >
        <span class="bookin-app__icon bookin-app__icon_ios" v-if="isIOS"></span>
        <span class="bookin-app__icon bookin-app__icon_android" v-else></span>
        <div class="bookin-app__text">
          <p class="bookin-app__text_title regular-p6">Приложение Суточно.ру</p>
          <p class="bookin-app__text_subtitle semibold-p5" v-if="isIOS">
            Скачать в App Store
          </p>
          <p class="bookin-app__text_subtitle semibold-p5" v-else>
            Скачать в Google Play
          </p>
        </div>
      </a>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const { t } = useI18n();

const store = useStore();

const isIOS = computed(() => store.state.user.isIOS || false);
</script>

<style lang="scss" scoped>
.bookin-app {
  margin-top: 54px;
  background-color: var(--color-gray-g3);
  border-radius: 20px;
  padding: 20px;
  background-image: url("@/assets/img/main/bookinapp-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  gap: 37px;

  &__button {
    display: flex;
    background-color: var(--color-white);
    box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.02),
      0px 5px 10px rgba(20, 45, 136, 0.02);
    border-radius: 8px;
    padding: 10px 17px;
    align-items: center;
  }

  &__icon {
    display: block;
    width: 25px;
    height: 27px;
    margin-right: 7px;
    background-position: center center;
    background-size: cover;
    &_android {
      background-image: url("@/assets/img/icon/google-play.svg");
    }
    &_ios {
      background-image: url("@/assets/img/icon/app-store.svg");
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    &_title {
      margin: 0;
      color: var(--color-gray-g5);
    }
    &_subtitle {
      margin: 0;
      color: var(--color-main-black);
    }
  }

  &__textes {
    width: 100%;
  }

  &__title {
    color: var(--color-white);
    margin-bottom: 10px;
    max-width: 260px;
  }

  &__subtitle {
    color: var(--color-white);
    margin-bottom: 16px;
    max-width: 240px;
  }

  &__apps {
    display: flex;
    flex-direction: column;
    min-width: 152px;
    max-width: 152px;
    justify-content: flex-end;
    margin-right: -10px;

    a {
      margin-bottom: -15px;
    }

    img {
      width: 100%;
    }
  }

  &__images {
    display: flex;
    gap: 26px;
  }

  &__qr {
    display: flex;
    align-items: flex-end;
  }

  &__code-img {
    background: var(--color-white);
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.02),
      0px 5px 10px rgba(20, 45, 136, 0.02);
    min-width: 91px;
    max-width: 91px;
    height: 90px;

    img {
      width: 100%;
    }
  }

  &__qr-text {
    color: var(--color-gray-g5);
    background: var(--color-gray-g2);
    border-radius: 18px 0px 18px 20px;
    padding: 10px 15px;
    margin-right: 6px;
  }
}
</style>
