import { useGtm } from "@gtm-support/vue-gtm";

/**
 *
 * Главная страница
 *
 * @module main
 *
 * @function
 *
 */

export default function () {
  useGtm().trackEvent({
    event: "crto_homepage",
    crto: {
      email: "79054025255fb1a26e4bc422aef54eb4",
    },
  });
}
