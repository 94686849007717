<i18n lang="json">
    {
      "ru": {
        "routDirectionTitle": "Идеи для отпуска",

        "ideaLabel": "Маршруты",
        "ideaLabel2": "С детьми",
        "ideaLabel3": "Маршруты",
        "ideaLabel4": "Что посмотреть",
        "ideaLabel5": "Telegram",

        "ideaDescription": "Недорогие курорты для&nbsp;отдыха на&nbsp;море",
        "ideaDescription2": "Пляжи Краснодарского края",
        "ideaDescription3": "На машине по России",
        "ideaDescription4": "Гид по Кавказским Минеральным Водам",
        "ideaDescription5": "Больше интересного в Телеграм-канале"
      },
      "en": {
        "routDirectionTitle": "Vacation Ideas",

        "ideaLabel": "Routes",
        "ideaLabel2": "With children",
        "ideaLabel3": "Routes",
        "ideaLabel4": "What to see",
        "ideaLabel5": "Telegram",

        "ideaDescription": "Inexpensive resorts for a seaside holiday",
        "ideaDescription2": "Beaches of the Krasnodar region",
        "ideaDescription3": "By car in Russia",
        "ideaDescription4": "Guide to Caucasian Mineral Waters",
        "ideaDescription5": "More interesting things in the Telegram channel"
      }
    }
</i18n>

<template>
  <div class="route-direction">
    <p class="route-direction__title heading-h3">{{ t("routDirectionTitle") }}</p>
    <div class="route-direction__container">
      <div
        class="route-direction__card"
        v-for="(card, i) in directionCards"
        :class="{ 'route-direction__card--empty': !card?.img }"
        :key="i"
      >
        <a :href="card.link" :target="card?.img ? '_self' : '_blank'">
          <span
            class="route-direction__card-label card-label"
            :class="{
              'card-label--blue': !card?.img,
              [!card?.img ? 'medium-p6' : 'semibold-p6']: true,
            }"
          >
            {{ t(`${card.label}`) }}
          </span>

          <picture v-if="card?.img">
            <source
              v-if="card.imgWebp"
              :srcset="card.imgWebp"
              type="image/webp"
            />
            <img
              :src="card.img"
              loading="lazy"
              :alt="t(`${card.description}`)"
            />
          </picture>

          <span class="route-direction__card-title heading-h6" v-html="t(`${card.description}`)"></span>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const images = import.meta.glob([
  "assets/img/main/naMore.jpg",
  "assets/img/main/naMore.webp",
  "assets/img/main/Beaches-Krasnodar.jpg",
  "assets/img/main/Beaches-Krasnodar.webp",
  "assets/img/main/By-car-in-Russia.jpg",
  "assets/img/main/By-car-in-Russia.webp",
  "assets/img/main/otdyhNaKmv.jpg",
  "assets/img/main/otdyhNaKmv.webp",
], { eager: true, query: "?url", import: "default" });

const directionCards = [
  {
    link: "/info/gde-nedorogo-otdohnut-na-more-letom?from=mainpage",
    label: "ideaLabel3",
    description: "ideaDescription",
    img: images["/assets/img/main/naMore.jpg"],
    imgWebp: images["/assets/img/main/naMore.webp"],
  },
  {
    link: "/info/plyazhi-krasnodarskogo-kraya?from=mainpage",
    label: "ideaLabel2",
    description: "ideaDescription2",
    img: images["/assets/img/main/Beaches-Krasnodar.jpg"],
    imgWebp: images["/assets/img/main/Beaches-Krasnodar.webp"],
  },
  {
    link: "/info/na-mashine-po-rossii?from=mainpage",
    label: "ideaLabel",
    description: "ideaDescription3",
    img: images["/assets/img/main/By-car-in-Russia.jpg"],
    imgWebp: images["/assets/img/main/By-car-in-Russia.webp"],
  },
  {
    link: "/info/otdyh-na-kmv?from=mainpage",
    label: "ideaLabel4",
    description: "ideaDescription4",
    img: images["/assets/img/main/otdyhNaKmv.jpg"],
    imgWebp: images["/assets/img/main/otdyhNaKmv.webp"],
  },
  {
    link: "https://t.me/sutochnoru",
    label: "ideaLabel5",
    description: "ideaDescription5",
  },
];
</script>

<style lang="scss" scoped>
.route-direction {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  margin-top: 70px;

  &__title {
    text-align: center;
    margin: 0;
  }

  &__container {
    display: flex;
    gap: 16px;
  }

  &__card {
    position: relative;
    min-height: 263px;
    min-width: 219px;
    border-radius: 18px;
    overflow: hidden;

    a {
      position: relative;
      height: 100%;
      display: block;

      &:hover {
        img {
          transform: scale(1.1);
        }
      }

      &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 48.28%,
          rgba(0, 0, 0, 0.3) 100%
        );
        z-index: 1;
      }
    }

    &--empty {
      padding-right: 16px;
      box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.02),
        0px 5px 10px rgba(20, 45, 136, 0.02);

      a {
        background: var(--color-white);
        border-radius: 18px;
        z-index: 2;

        &:before {
          content: none;
        }
      }

      &:before,
      &:after {
        content: "";
        width: 91px;
        height: 100%;
        border-radius: 18px;
        position: absolute;
        top: 0;
      }

      &:before {
        background: #edf0f9;
        right: 8px;
        box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.02),
          0px 5px 10px rgba(20, 45, 136, 0.02);
        z-index: 1;
      }

      &:after {
        background: #e2e6f0;
        right: 0;
        box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.08),
          0px 4px 15px rgba(20, 45, 136, 0.06);
        z-index: 0;
      }

      .route-direction__card-title {
        color: var(--color-main-black);
        text-shadow: none;
        font-size: 17px;
        padding-right: 25px;
      }
    }
  }

  &__card-title {
    position: absolute;
    bottom: 18px;
    left: 0;
    padding: 0 18px;
    color: var(--color-white);
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15),
      0px 4px 10px rgba(0, 0, 0, 0.2);
    z-index: 2;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
  }
}
</style>
