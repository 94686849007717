<template>
  <div class="main">
    <div class="container">
      <TitleMain />
      <SearchWidget />

      <div v-if="isUtair || isAeroflot" class="pravila-wrap">
        <a v-if="isUtair" class="pravila-programmy__utair" target="_blank" href="https://media.utair.ru/status">
          Utair Status: получайте до 13 % милями от стоимости брони
        </a>
        <a v-if="isAeroflot" class="pravila-programmy__aeroflot" target="_blank" href="https://www.aeroflot.ru/ru-ru/afl_bonus">
          Получайте мили «Аэрофлот Бонус» за оплату бронирований на сайте aeroflot.sutochno.ru
        </a>
      </div>

      <template v-if="!whitelabel">
        <GetCashback />
        <BrieflyAboutUs />
        <RouteDirection />
        <WhereToGo />
        <BookInApp />
      </template>
    </div>

    <!-- <CountReviewsAccommodation /> -->

    <div class="container" v-if="!whitelabel">
      <PopularAbroad />
      <WhereToBegin />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

import TitleMain from "./TitleMain.vue";
import RouteDirection from "./RouteDirection.vue";
import BrieflyAboutUs from "./BrieflyAboutUs.vue";
import GetCashback from "./GetCashback.vue";
import WhereToGo from "./WhereToGo.vue";
import BookInApp from "./BookInApp.vue";
import CountReviewsAccommodation from "./CountReviewsAccommodation.vue";
import PopularAbroad from "./PopularAbroad.vue";
import WhereToBegin from "./WhereToBegin.vue";
import SearchWidget from "./SearchWidget.vue";

const store = useStore();

const whitelabel = computed(() => store.state.user.whitelabel);

const isUtair = computed(() => whitelabel.value && whitelabel.value.name === "utair");
const isAeroflot = computed(() => whitelabel.value && whitelabel.value.name === "aeroflot");
</script>

<style lang="scss" scoped>
.main {
  .container {
    max-width: 1160px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;

    @media (max-width: 1160px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

@media (min-width: 930px) {
  .pravila-wrap {
    text-align: center;
  }
  .pravila-programmy {
    &__aeroflot,
    &__utair {
      padding: 20px;
      border-radius: 25px;
      background-color: #fff;
      color: #000;
      z-index: 1;
      position: relative;
      bottom: -100px;
      &:hover {
        color: #000;
      }
    }
  }
}
</style>
