<i18n lang="json">
{
  "ru": {
    "leftTitle1": "В отпуск намылились?",
    "rightTitle": "В приложении \nбольше!",
    "heading": "Получайте кэшбэк бонусами \n до 30% от общей стоимости \n проживания.",
    "description": "Максимальный кэшбэк \n в мобильном приложении \n Суточно.ру!",
    "moreBtn": "Узнать о кэшбэке",
    "withJacuzzi": "Квартиры с джакузи",
    "withPool": "Жильё с бассейном",
    "mountainView": "С видом на горы",
    "glamplings": "Глэмпинги Карелии",
    "forParty": "Коттеджи для вечеринки",
    "withBreakfast": "Отели с завтраком",
    "download": "Установить приложение"
  },
  "en": {
    "leftTitle1": "Are you going on vacation?",
    "rightTitle": "More in the app!",
    "heading": "Get cashback up to 30% \n of the total cost of your stay.",
    "description": "Maximum cashback \n in the mobile application \n Sutochno.ru!",
    "moreBtn": "Learn about cashback",
    "withJacuzzi": "Apartments with Jacuzzi",
    "withPool": "Housing with a pool",
    "mountainView": "Mountain view",
    "glamplings": "Glampings of Karelia",
    "forParty": "Party Cottages",
    "withBreakfast": "Hotels with breakfast",
    "download": "Install app"
  }
}
</i18n>

<template>
  <div class="get-cashback">
    <div class="get-cashback__right">
      <div class="get-cashback__group">
        <p class="get-cashback__right-title heading-h5">
          {{ t("rightTitle") }}
        </p>
        <p class="get-cashback__heading semibold-p8" >
          {{ t("heading") }}
        </p>
        <p class="get-cashback__description regular-p6">
          {{ t("description") }}
        </p>
        <!-- Поставить актуальную ссылку и подставить класс для кнопки из UI Kit (сейчас стили в scoped) -->
        <span @click="showCashbackModal" class="get-cashback__more semibold-p8">
          {{ t("moreBtn") }}
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.41602 11.8108L10.416 8.81079L7.41602 5.81079"
              stroke="white"
              stroke-width="1.4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </div>
      <UIButton buttonTheme="red" @click="openApp">
        {{ t("download") }}
      </UIButton>
    </div>
    <div class="get-cashback__left">
      <p class="get-cashback__left-title">
        {{ t("leftTitle1") }}
      </p>
      <div class="get-cashback__labels">
        <a
          :href="label.link"
          target="_blank"
          v-for="(label, i) in labels"
          :key="i"
          class="medium-p6"
        >
          {{ t(`${label.textCode}`) }}
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { ModalNew } from "@sutochno/modal";
import UIButton from "@/components/UIKit/UIButton.vue";
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex'

const { t } = useI18n();

// Поменять все ссылки на актуальные
const labels = [
  {
    textCode: "withJacuzzi",
    link: "/front/searchapp/search?is_apartment=1&jacuzzi=1&from=mainpage&guests_adults=2&id=397367&type=city&term=%D0%A1%D0%B0%D0%BD%D0%BA%D1%82-%D0%9F%D0%B5%D1%82%D0%B5%D1%80%D0%B1%D1%83%D1%80%D0%B3&SW.lat=59.744311&SW.lng=30.043373&NE.lat=60.090935&NE.lng=30.566426",
  },
  {
    textCode: "mountainView",
    link: "/front/searchapp/search?view_mountains=1&from=mainpage&guests_adults=2&id=410738&type=city&term=%D0%90%D1%80%D1%85%D1%8B%D0%B7&SW.lat=43.509017&SW.lng=41.169035&NE.lat=43.584127&NE.lng=41.381406 )",
  },
  {
    textCode: "withPool",
    link: "/front/searchapp/search?pool=1&from=mainpage&guests_adults=2&id=287363&type=city&term=%D0%93%D0%B5%D0%BB%D0%B5%D0%BD%D0%B4%D0%B6%D0%B8%D0%BA&SW.lat=44.533968&SW.lng=37.968957&NE.lat=44.614525&NE.lng=38.134193)",
  },
  {
    textCode: "withBreakfast",
    link: "/front/searchapp/search?is_hotel=1&has_breakfast=1&from=mainpage&guests_adults=2&id=397367&type=city&term=%D0%A1%D0%B0%D0%BD%D0%BA%D1%82-%D0%9F%D0%B5%D1%82%D0%B5%D1%80%D0%B1%D1%83%D1%80%D0%B3&SW.lat=59.744311&SW.lng=30.043373&NE.lat=60.090935&NE.lng=30.566426)",
  },
];

const showDropdown1 = ref(false);
const showDropdown2 = ref(false);
const store = useStore();

const showCashbackModal = () => {
  store.commit('user/setShowCashbackModal', true);
}

function openApp(){
  window.open(
    "https://redirect.appmetrica.yandex.com/serve/388964938577662096",
    "_blank"
  );
}
</script>

<style lang="scss" scoped>
.get-cashback {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 50px;

  &__left-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: var(--color-white);
    margin: 0;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15),
      0px 4px 10px rgba(0, 0, 0, 0.2);
    max-width: 270px;
  }

  &__group {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  &__description {
    color: var(--color-gray-g2);
    margin: 0;
    white-space: pre-line;
  }

  &__heading {
    color: var(--color-gray-g2);
    margin: 0;
    white-space: pre-line;
  }

  &__labels {
    margin-top: 14px;
    max-width: 315px;
    display: flex;
    flex-wrap: wrap;
    gap: 6px;

    a {
      color: var(--color-white);
      padding: 3px 10px;
      background: rgba(28, 28, 28, 0.4);
      backdrop-filter: blur(10px);
      border-radius: 20px;
      text-align: center;
    }
  }

  &__left {
    width: 100%;
    height: 270px;
    border-radius: 16px;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 37.14%,
        rgba(0, 0, 0, 0.4) 100%
      ),
      url("@/assets/img/main/main-left-mob.jpg"), #d9d9d9;
    background-size: 116%;
    background-position: 85% 16%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__right-title {
    padding-right: 30px;
    margin: 0;
    color: var(--color-white);
    white-space: pre-line;
  }

  &__right {
    width: 100%;
    background-image: url("@/assets/img/main/main-right-mob.jpg");
    background-color: var(--color-gray-g3);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 16px;
    padding: 28px 22px 22px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    button{
      margin-top: 22px;
      font-size: 14px;
      padding: 11px 24px;
    }
  }

  &__more {
    background: transparent;
    color: var(--color-white);
    display: flex;
    align-items: center;
    width: max-content;
    border: none;
    padding: 0;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.modal__content::-webkit-scrollbar {
  width: 8px;
}

.modal__content::-webkit-scrollbar-track {
  background: white;
}

.modal__content::-webkit-scrollbar-thumb {
  background-color: #5a5d63;
  border-radius: 20px;
}

.dropdown__item-icon::before {
  transition: all 0.3s;
  transform: rotateX(180deg);
}

.rotate-icon::before {
  transform: rotateX(0);
}
</style>
