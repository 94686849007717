<template>
  <div class="briefly">
    <div class="briefly__el" v-for="(el, i) in brieflyElements" :key="i">
      <div class="img">
        <img
          :src="el.image"
          :alt="el.title"
        />
      </div>
      <p class="briefly__title semibold-p3" v-html="el.title"></p>
    </div>
  </div>
</template>

<script setup>
const images = import.meta.glob("assets/img/main/briefly-*.png", { eager: true, query: "?url", import: "default" });
const brieflyElements = [
  {
    title: "300 тысяч вариантов: <br>квартиры, отели, гостевые дома",
    image: images["/assets/img/main/briefly-1.png"],
  },
  {
    title: "Цены напрямую <br>от хозяев жилья",
    image: images["/assets/img/main/briefly-2.png"],
  },
  {
    title: "Кэшбэк бонусами после <br>каждой поездки",
    image: images["/assets/img/main/briefly-3.png"],
  },
  {
    title: "Круглосуточная служба <br>поддержки",
    image: images["/assets/img/main/briefly-4.png"],
  },
];
</script>

<style lang="scss" scoped>
.briefly {
  margin-top: 76px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  &__title {
    margin-top: 15px;
  }

  &__el {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    span {
      width: 86px;
      height: 86px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: var(--color-gray-g3);
      border-radius: 100%;
    }
  }
  .img{
    width: 86px;
    height: 109px;

    img{
      width: 100%;
      height: 100%;
    }
  }
}
</style>
