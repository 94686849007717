<template>
  <MainDesktop v-if="viewPort === 'desktop'" />
  <MainMobile v-else  />
</template>

<script setup>
import MainDesktop from '../components/desktop/pages/Main.vue';
import MainMobile from '../components/mobile/pages/Main.vue';

import { useStore } from "vuex";

const store = useStore();

const viewPort = computed(() => store.state.user.viewPort);

definePageMeta({
  name: "Main",
  path: "/",
})
</script>